
<template>
   <div>
      <div class="flex items-center w-full p-4 bg-primary text-white top-header-page" @click.stop="backHome()">
         <feather-icon icon="ChevronLeftIcon"  class="cursor-pointer mr-4" @click.stop="backHome()"></feather-icon>
         <h4 class="text-white">Data Permohonan</h4>
      </div>

      <vx-card title-color="primary" title="Formulir data digital">
         <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Nama Pemilik Sertipikat" v-model="dataNama" class="w-full"  v-validate="'required'" name="Nama"/>
               <span class="text-danger text-sm" v-show="errors.has('Nama')">{{ errors.first('Nama') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Nomor Sertipikat" v-model="dataNomor" class="w-full" v-validate="'required'" name="Nomor"/>
               <span class="text-danger text-sm" v-show="errors.has('Nomor')">{{ errors.first('Nomor') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <small class="date-label">Umur</small>
               <vx-input-group>
                  <vs-input v-model="dataUmur" v-validate="'max:2|numeric|required'" name="Umur"/>
                  <template slot="append">
                     <div class="append-text bg-primary">
                     <span>Tahun</span>
                     </div>
                  </template>
               </vx-input-group>
               <span class="text-danger text-sm" v-show="errors.has('Umur')">{{ errors.first('Umur') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Pekerjaan" v-model="dataPekerjaan" class="w-full" v-validate="'required'" name="Pekerjaan"/>
               <span class="text-danger text-sm" v-show="errors.has('Pekerjaan')">{{ errors.first('Pekerjaan') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="No. KTP" v-model="dataKTP" class="w-full" v-validate="'digits:16|numeric|required'" name="KTP"/>
               <span class="text-danger text-sm" v-show="errors.has('KTP')">{{ errors.first('KTP') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mt-4">
               <vs-textarea label="Alamat" v-model="dataAlamat" class="w-full" v-validate="'required'" name="Alamat"/>
               <span class="text-danger text-sm" v-show="errors.has('Alamat')">{{ errors.first('Alamat') }}</span>
            </div>
         </div>
      </vx-card>

      <div class="my-5 flex justify-end">
         <vs-switch v-model="dataKuasa">
            <span slot="on">Dikuasakan</span>
            <span slot="off">Tidak Dikuasakan</span>
         </vs-switch>
      </div>

      <vx-card title-color="primary" v-if="dataKuasa">
         <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Nama" v-model="dataKuasaNama" class="w-full"  v-validate="'required'" name="Kuasa Nama"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Nama')">{{ errors.first('Kuasa Nama') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <small class="date-label">Umur</small>
               <vx-input-group>
                  <vs-input v-model="dataKuasaUmur" v-validate="'max:2|numeric|required'" name="Kuasa Umur"/>
                  <template slot="append">
                     <div class="append-text bg-primary">
                     <span>Tahun</span>
                     </div>
                  </template>
               </vx-input-group>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Umur')">{{ errors.first('Kuasa Umur') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="Pekerjaan" v-model="dataKuasaPekerjaan" class="w-full" v-validate="'required'" name="Kuasa Pekerjaan"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Pekerjaan')">{{ errors.first('Kuasa Pekerjaan') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-input label="No. KTP" v-model="dataKuasaKTP" class="w-full" v-validate="'digits:16|numeric|required'" name="Kuasa KTP"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa KTP')">{{ errors.first('Kuasa KTP') }}</span>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mt-4">
               <vs-textarea label="Alamat" v-model="dataKuasaAlamat" class="w-full" v-validate="'required'" name="Kuasa Alamat"/>
               <span class="text-danger text-sm" v-show="errors.has('Kuasa Alamat')">{{ errors.first('Kuasa Alamat') }}</span>
            </div>
         </div>
      </vx-card>
      
      <vx-card title-color="primary" title="Bidang tanah terletak">
         <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-select
               label="Kecamatan"
               v-model="dataKecamatan"
               v-validate="'required'"
               class="w-full"
               @change="mountKelurahan()"
               @click.prevent="changeKecamatan()"
               >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKecamatan"/>
               </vs-select>
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mt-4">
               <vs-select
               label="Kelurahan"
               v-model="dataKelurahan"
               v-validate="'required'"
               class="w-full"
               @change="pilihKelurahan()"
               @click.prevent="changeKelurahan()"
               >
                  <vs-select-item value="0" text="Pilih"/>
                  <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listKelurahan"/>
               </vs-select>
            </div>
         </div>
      </vx-card>
      
      <div class="">
         <vs-button color="warning" type="relief" class="w-full mt-5" @click.stop="updateData()"> SAVE DATA </vs-button>

         <!--
         <div class="dropdown-button-container w-full mt-5">
            <vs-button class="btnx bg-white text-black" type="filled">Lampiran</vs-button>

            <vs-dropdown vs-trigger-click>
               <vs-button class="btn-drop" style="width:90%;" type="filled" icon="expand_more"></vs-button>
               <vs-dropdown-menu>
                  <vs-dropdown-item @click.stop="printPermohonan()">
                     <p>Surat Permohonan</p>
                     <vs-divider class="my-1"></vs-divider>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click.stop="printGantiBlanko()">
                     <p>Ganti Blanko</p>
                     <vs-divider class="my-1"></vs-divider>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click.stop="printPemecahan()">
                     <p>Pemecahan</p>
                     <vs-divider class="my-1"></vs-divider>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click.stop="printPemecahan()">
                     <p>Penggabungan</p>
                     <vs-divider class="my-1"></vs-divider>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click.stop="printPengukuran()">
                     <p>Pengukuran dan Pemetaan</p>
                     <vs-divider class="my-1"></vs-divider>
                  </vs-dropdown-item>
               </vs-dropdown-menu>
            </vs-dropdown>
         </div>
         -->

         <vs-button color="success" type="relief" class="w-full mt-5" @click.stop="toJadwal()"> JADWAL </vs-button>
      </div>

      <!-- LAMPIRAN PDF 
         <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1200"
            filename="M"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="f4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            :htmlToPdfOptions="htmlToPdfOptions"
            ref="Permohonan"
         >
            <section slot="pdf-content" style="color:#000;">
            <section class="pdf-item px-6">
               <div>
                  
                  <div class="vx-row mb-4">
                     <div class="vx-col w-1/2 text-left">
                        <p>Nomor : </p>
                        <p>Lampiran : </p>
                        <p>Perihal : </p>
                     </div>
                     <div class="vx-col w-1/2 text-left pl-5">
                        <p class="mb-4">Makassar, Tgl. {{ this.formatDate() }}</p>
                        <p class="ml-4 mb-0">Kepada Yth :</p>
                        <p class="ml-4 mb-0">Sdr. Kepala Kantor Pertanahan</p>
                        <p class="ml-4 mb-0">Kota Makassar</p>
                        <p class="ml-4 mb-0">di - </p>
                        <p class="ml-12 mb-0">Makassar</p>
                     </div>
                  </div>
                  
                  <div class="mb-4">
                  <p class="mb-2">Dengan Hormat, </p>
                  <p>Yang bertandatangan dibawah ini : </p>
                  </div>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nama</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataNama }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Umur</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataUmur }} tahun</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Pekerjaan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataPekerjaan }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nomor KTP</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataKTP }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Alamat</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataAlamat }}</div> 
                     </div>
                  </div>
                  </div>
                  
                  <div class="mb-4">
                  <p class="mb-2">Dengan ini, mengajukan permohonan 
                     
                     <b>{{ dataLayanan.relation.layanan_group[0].name }} {{ dataLayanan.task_title }}</b>. Atas bidang tanah :
                     
                  </p>
                  <p class="mb-2">Terletak di </p>
                  </div>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Kelurahan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataKelurahanNama }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Kecamatan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataKecamatanNama }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Kota</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">Makassar</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nomor Hak</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataNomor }}</div> 
                     </div>
                  </div>
                  </div>
                  
                  <div class="mb-4">
                  <p class="mb-2">Untuk melengkapi permohohonan dimaksud, bersama ini kami lampirkan : </p>
                  </div>
                  
                  <div class="mb-4 ml-base mr-2">
                  
                     <div class="vx-row mb-2" v-for="(syarat, index) in dataLayanan.relation.persyaratan" :key="index">
                        <div class="vx-col w-full">{{index+1}}. {{syarat.name}}</div>
                     </div>
                  
                  </div>

                  <div class="w-1/3 ml-auto mt-auto">
                  <p class="mb-10">Hormat kami, </p>
                  <p class="mb-10">materai 10.000</p>
                  <p> <b>{{dataNama}}</b> </p>
                  </div>

               </div>
            </section>
            </section>
         </vue-html2pdf>
         
         <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1200"
            filename="M"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="f4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            :htmlToPdfOptions="htmlToPdfOptions"
            ref="GantiBlanko"
         >
            <section slot="pdf-content" style="color:#000;">

            <section class="pdf-item mr-6 p-6">
               <div class="text-center mb-10">
                  <h2>SKET GANTI BLANGKO</h2>
               </div>
               <vs-divider></vs-divider>
               <div style="margin-top: 55rem;">
                  <div class="w-1/3 ml-auto">
                  <p class="mb-4">Makassar, Tgl. {{ this.formatDate() }}</p>
                  <p class="mb-12">Pemohon</p>
                  <p class="mb-12"></p>
                  <p class="mt-10"> <b>{{dataNama}}</b> </p>
                  </div>
               </div>
            </section>

            <div class="html2pdf__page-break"/>

            <section class="pdf-item mr-6 p-6">
               <div class="w-1/2 ml-auto">
                  <p class="mb-4">LAMPIRAN I</p>
                  <p class="mb-4">PERATURAN MENTERI AGRARIA DAN TATA RUANG/KEPALA BADAN PERTANAHAN NASIONAL NOMOR 16 TAHUN 2021 TENTANG PERUBAHAN KETIGA KETIGA ATAS PERATURAN MENTERI NEGARA AGRARIA/KEPALA BADAN PERTANAHAN NASIONAL NOMOR 3 TAHUN 1997 TENTANG KETENTUAN PELAKSANAAN PERATURAN PEMERINTAH NOMOR 24 TAHUN 1997 TENTANG PENDAFTARAN TANAH</p>
               </div>
               
               <div class="border border-solid p-10">
                  <div class="text-center mb-5">
                  <h5>SURAT PERNYATAAN</h5>
                  <h5>PEMASANGAN TANDA BATAS DAN PERSETUJUAN PEMILIK YANG BERBATASAN</h5>
                  </div>
                  
                  <div class="mb-4">
                  <p>Yang bertandatangan dibawah ini : </p>
                  </div>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nama</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataNama }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Umur</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataUmur }} tahun</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Pekerjaan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataPekerjaan }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nomor KTP</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataKTP }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Alamat</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataAlamat }}</div> 
                     </div>
                  </div>
                  </div>

                  <p class="mb-4">Adalah pemilik tanah Kohir No. {{dataNomor}} Persil ....... Kelas ......... seluas ....... &#177; m2 yang terletak di Blok ........... Desa/Kelurahan {{dataKelurahanNama}} Kecamatan {{dataKecamatanNama}} Kota Makassar berdasarkan ......</p>

                  <p>Dengan ini menyatakan bahwa tanah tersebut :</p>
                  
                  <div class="mb-4">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">1.</vs-col>
                     <vs-col vs-w="11">Telah dipasang patok/tanda batas;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">2.</vs-col>
                     <vs-col vs-w="11">Terhadap patok yang dipasang tersebut tidak ada pihak yang berkeberatan;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Apabila ternyata luas hasil ukur lebih kecil dari luas yang tertulis pada alas hak/akta peralihan hak/surat-surat lain dalam, berkas permohonan sertipikat kami menerima luas hasil ukuran petugas Kantor Pertanahan; dan</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Apabila luas hasil pengukuran ternyata lebih besar dari yang tertulis pada alas hak/akta peralihan hak/surat-surat lain dalam berkas permohonan sertipikat, saya tidak mengambil hak orang lain dan tidak ada perolehan lain selain bukti pemilikan tersebut di atas, apabila ada gugatan/keberatan dari pihak lain, saya akan bertanggung jawab.</vs-col>
                  </vs-row>
                  </div>

                  <p>Demikian Surat Penyataan ini saya buat dengan sebenarnya dengan penuh tanggung jawab dan saya bersedia mengangkat sumpah bila diperlukan, apabila pernyataan ini tidak benar saya bersedia dituntut di hadapan pihak berwenang.</p>

                  <p class="w-full flex justify-end mt-base"> Menyetujui ..... </p>

               </div>
            </section>

            <div class="html2pdf__page-break"/>

            <section class="pdf-item mr-6 p-6">
               
               <div class="border border-solid p-10">
                  
                  <div class="vx-row mb-base">
                  <div class="vx-col w-1/2">
                     <p>Menyetujui pemilik yang berbatasan:</p>
                     <p>Sebelah Utara:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                     <p>Sebelah Timur:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                     <p>Sebelah Selatan:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                     <p>Sebelah Barat:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                  </div>
                  
                  <div class="vx-col w-1/2">
                     <p class="mb-12">.................., .........................</p>
                     <p class="text-center mb-12">Yang membuat pernyataan</p>
                     <p class="text-center mb-12">(Materai Cukup)</p>
                     <p class="text-center">
                        ...............................................
                     </p>
                  </div>
                  </div>

                  <p class="mb-base"><b><i>* melampirkan fotokopi KTP para pihak yang bersebelahan/ berbatasan atau diketahui oleh Kepala Desa/ lurah</i></b></p>

                  <p><b>SKETSA BIDANG TANAH</b></p>
                  <p>Informasi Sketsa:</p>
                  
                  <div class="mb-base">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">1.</vs-col>
                     <vs-col vs-w="11">Harus ada alamat jelas;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">2.</vs-col>
                     <vs-col vs-w="11">Gambaran lokasi tetangga batas;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Lokasi relatif dari tempat umum (contoh: Masjid, SPBU, dan lain-lain) atau unsur geografis (jalan, sungai,jembatan).</vs-col>
                  </vs-row>
                  </div>
                  
                  <div class="border border-solid mb-base">
                  <p class="ml-2" style="margin-bottom:250px;">Kolom Gambar Sketsa Bidang:</p>
                  </div>

                  <div class="w-1/3 ml-auto text-center">
                  <p class="mb-4">Mengetahui</p>
                  <p class="mb-12">Kepala Desa / Lurah,</p>
                  <p class="mb-12"></p>
                  <p class="mt-10"> .................. </p>
                  </div>

               </div>
            </section>
            </section>
         </vue-html2pdf>
         
         <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1200"
            filename="M"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="f4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            :htmlToPdfOptions="htmlToPdfOptions"
            ref="Pemecahan"
         >
            <section slot="pdf-content" style="color:#000;">
            <section class="pdf-item mr-6 p-6">
               <div class="text-center mb-10">
                  <h2>SKET PEMECAHAN</h2>
               </div>
               <vs-divider></vs-divider>
               <div style="margin-top: 55rem;">
                  <div class="w-1/3 ml-auto">
                  <p class="mb-4">Makassar, Tgl. {{ this.formatDate() }}</p>
                  <p class="mb-12">Pemohon</p>
                  <p class="mb-12"></p>
                  <p class="mt-10"> <b>{{dataNama}}</b> </p>
                  </div>
               </div>
            </section>
            </section>
         </vue-html2pdf>
         
         <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1200"
            filename="M"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="f4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            :htmlToPdfOptions="htmlToPdfOptions"
            ref="Pengukuran"
         >
            <section slot="pdf-content" style="color:#000;">

            <section class="pdf-item mr-6 px-6">
               <div class="text-center mb-10">
                  <h2>SKET PENGUKURAN</h2>
               </div>
               <vs-divider></vs-divider>
               <div style="margin-top: 55rem;">
                  <div class="w-1/3 ml-auto">
                  <p class="mb-4">Makassar, Tgl. {{ this.formatDate() }}</p>
                  <p class="mb-12">Pemohon</p>
                  <p class="mb-12"></p>
                  <p class="mt-10"> <b>{{dataNama}}</b> </p>
                  </div>
               </div>
            </section>

            <div class="html2pdf__page-break"/>

            <section class="pdf-item mr-6 px-6">
               <div class="w-1/2 ml-auto">
                  <p class="mb-4">LAMPIRAN I</p>
                  <p class="mb-4">PERATURAN MENTERI AGRARIA DAN TATA RUANG/KEPALA BADAN PERTANAHAN NASIONAL NOMOR 16 TAHUN 2021 TENTANG PERUBAHAN KETIGA KETIGA ATAS PERATURAN MENTERI NEGARA AGRARIA/KEPALA BADAN PERTANAHAN NASIONAL NOMOR 3 TAHUN 1997 TENTANG KETENTUAN PELAKSANAAN PERATURAN PEMERINTAH NOMOR 24 TAHUN 1997 TENTANG PENDAFTARAN TANAH</p>
               </div>
               
               <div class="border border-solid p-10 mb-12">
                  <div class="text-center mb-5">
                  <h5>SURAT PERNYATAAN</h5>
                  <h5>PEMASANGAN TANDA BATAS DAN PERSETUJUAN PEMILIK YANG BERBATASAN</h5>
                  </div>
                  
                  <div class="mb-4">
                  <p>Yang bertandatangan dibawah ini : </p>
                  </div>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nama</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataNama }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Umur</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataUmur }} tahun</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Pekerjaan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataPekerjaan }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nomor KTP</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataKTP }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Alamat</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataAlamat }}</div> 
                     </div>
                  </div>
                  </div>

                  <p class="mb-4">Adalah pemilik tanah Kohir No. {{dataNomor}} Persil ....... Kelas ......... seluas ....... &#177; m2 yang terletak di Blok ........... Desa/Kelurahan {{dataKelurahanNama}} Kecamatan {{dataKecamatanNama}} Kota Makassar berdasarkan ......</p>

                  <p>Dengan ini menyatakan bahwa tanah tersebut :</p>
                  
                  <div class="mb-4">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">1.</vs-col>
                     <vs-col vs-w="11">Telah dipasang patok/tanda batas;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">2.</vs-col>
                     <vs-col vs-w="11">Terhadap patok yang dipasang tersebut tidak ada pihak yang berkeberatan;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Apabila ternyata luas hasil ukur lebih kecil dari luas yang tertulis pada alas hak/akta peralihan hak/surat-surat lain dalam, berkas permohonan sertipikat kami menerima luas hasil ukuran petugas Kantor Pertanahan; dan</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Apabila luas hasil pengukuran ternyata lebih besar dari yang tertulis pada alas hak/akta peralihan hak/surat-surat lain dalam berkas permohonan sertipikat, saya tidak mengambil hak orang lain dan tidak ada perolehan lain selain bukti pemilikan tersebut di atas, apabila ada gugatan/keberatan dari pihak lain, saya akan bertanggung jawab.</vs-col>
                  </vs-row>
                  </div>

                  <p>Demikian Surat Penyataan ini saya buat dengan sebenarnya dengan penuh tanggung jawab dan saya bersedia mengangkat sumpah bila diperlukan, apabila pernyataan ini tidak benar saya bersedia dituntut di hadapan pihak berwenang.</p>

                  <p class="w-full flex justify-end mt-base"> Menyetujui ..... </p>

               </div>
            </section>

            <div class="html2pdf__page-break"/>

            <section class="pdf-item mr-6 px-6 mt-12">
               <div class="border border-solid px-10 pb-10 pt-5">
                  
                  <div class="vx-row mb-base">
                  <div class="vx-col w-1/2">
                     <p>Menyetujui pemilik yang berbatasan:</p>
                     <p>Sebelah Utara:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                     <p>Sebelah Timur:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                     <p>Sebelah Selatan:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                     <p>Sebelah Barat:</p>
                     <p>...............................................</p>
                     <p>(...............................................)</p>
                  </div>
                  
                  <div class="vx-col w-1/2">
                     <p class="mb-12">.................., .........................</p>
                     <p class="text-center mb-12">Yang membuat pernyataan</p>
                     <p class="text-center mb-12">(Materai Cukup)</p>
                     <p class="text-center">
                        ...............................................
                     </p>
                  </div>
                  </div>

                  <p class="mb-base"><b><i>* melampirkan fotokopi KTP para pihak yang bersebelahan/ berbatasan atau diketahui oleh Kepala Desa/ lurah</i></b></p>

                  <p><b>SKETSA BIDANG TANAH</b></p>
                  <p>Informasi Sketsa:</p>
                  
                  <div class="mb-base">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">1.</vs-col>
                     <vs-col vs-w="11">Harus ada alamat jelas;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">2.</vs-col>
                     <vs-col vs-w="11">Gambaran lokasi tetangga batas;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Lokasi relatif dari tempat umum (contoh: Masjid, SPBU, dan lain-lain) atau unsur geografis (jalan, sungai,jembatan).</vs-col>
                  </vs-row>
                  </div>
                  
                  <div class="border border-solid mb-base">
                  <p class="ml-2" style="margin-bottom:250px;">Kolom Gambar Sketsa Bidang:</p>
                  </div>

                  <div class="w-1/3 ml-auto text-center">
                  <p class="mb-4">Mengetahui</p>
                  <p class="mb-12">Kepala Desa / Lurah,</p>
                  <p class="mb-12"></p>
                  <p class="mt-10"> .................. </p>
                  </div>

               </div>
            </section>

            <div class="html2pdf__page-break"/>

            <section class="pdf-item mr-6 px-6">
               
               <div class="p-10">
                  <div class="text-center mb-5">
                  <h5>SURAT PERNYATAAN PENGUASAAN FISIK BIDANG TANAH</h5>
                  <vs-divider></vs-divider>
                  </div>
                  
                  <div class="mb-4">
                  <p>Yang bertandatangan dibawah ini : </p>
                  </div>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nama</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataNama }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Umur</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataUmur }} tahun</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Pekerjaan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataPekerjaan }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Nomor KTP</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataKTP }}</div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Alamat</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">{{ dataAlamat }}</div> 
                     </div>
                  </div>
                  </div>

                  <p class="mb-4">Dengan ini menyatakan bahwa saya dengan itikad baik telah menguasai/memiliki sebidang tanah yang terletak di:</p>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Jalan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">RT/RW</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Desa/Kelurahan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Kota</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Luas</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">NIB</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Status Tanah</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Dipergunakan Untuk</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>

                  </div>

                  <p class="">Batas-batas tanah:</p>
                  
                  <div class="mb-4 ml-base">
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Utara</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Timur</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Selatan</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>
                  <div class="vx-row mb-1">
                     <div class="vx-col w-1/6">Barat</div>
                     <div class="vx-col w-3/4 flex">
                        <div class="ml-5">:</div>
                        <div class="ml-5">
                        ..................................................................................................................
                        </div> 
                     </div>
                  </div>

                  </div>
                  
                  <div class="mb-4">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">1.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut adalah benar milik saya bukan milik orang lain dan statusnya adalah Tanah Negara/Tanah Ulayat/...*];</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">2.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut saya kuasai secara fisik sejak tahun yang sampai saat ini saya kuasai secara terus menerus;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">3.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut saya peroleh dari ............... sejak tahun ..........;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">4.</vs-col>
                     <vs-col vs-w="11">Penguasaan bidang tanah tersebut dengan itikad baik dan secara terbuka oleh saya sebagai yang berhak atas bidang tanah tersebut;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">5.</vs-col>
                     <vs-col vs-w="11">Perolehan tanah dibuat sesuai data yang sebenarnya dan apabila ternyata di kemudian hari terjadi permasalahan menjadi tanggung jawab pemohon sepenuhnya dan tidak akan melibatkan Kementerian;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">6.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut tidak terdapat konflik/sengketa/perkara dan keberatan dari pihak lain atas tanah yang dimiliki atau tidak dalam keadaan sengketa;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">7.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut tidak dijadikan/menjadi jaminan sesuatu utang/ tidak terdapat keberatan dari pihak Kreditur (<i>apabila dijadikan/menjadi jaminan sesuatu utang</i>) *];</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">8.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut bukan aset *];</vs-col>
                  </vs-row>
                  <vs-row class="ml-10">
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">a.</vs-col>
                     <vs-col vs-w="11">pemerintah/pemerintah daerah/Badan Usaha Milik Negara/Badan Usaha Milik Daerah; atau</vs-col>
                  </vs-row>
                  <vs-row class="ml-10">
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">b.</vs-col>
                     <vs-col vs-w="11">pemerintah/pemerintah daerah/Badan Usaha Milik Negara/Badan Usaha Milik Daerah lain, untuk permohonan Hak Pengelolaan atau Hak Pakai selama dipergunakan yang dimohon oleh instansi pemerintah.</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">9.</vs-col>
                     <vs-col vs-w="11">Bidang tanah tersebut berada diluar kawasan hutan, diluar arael yang dihentikan perizinannya pada hutan alam primer dan lahan gambut;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">10.</vs-col>
                     <vs-col vs-w="11">Bersedia untuk tidak mengurung atau menutup pekarangan atau bidang tanah lain dari lalu lintas umum, akses publik dan/atau jalan air;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">11.</vs-col>
                     <vs-col vs-w="11">Bersedia melepaskan tanah untuk kepentingan umum baik sebagian atau seluruhnya;</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">12.</vs-col>
                     <vs-col vs-w="11">Bersedia dan sanggup untuk tetap melaksanakan kegiatan Corporate Social Responsibility (CSR), untuk permohonan Hak Guna Bangunan dan Hak Pakai dengan jangka waktu yang diajukan oleh Pemohon, Badan Hukum yang menjalankan kegiatan usahanya di bidang dan/atau berkaitan dengan sumber daya alam;</vs-col>
                  </vs-row>
                  </div>

                  <p class="mb-4">Surat pernyataan ini saya buat dengan sebenar-benarnya dengan penuh tanggung jawab baik secara perdata maupun pidana, apabila dikemudian hari terdapat unsur-unsur yang tidak dibenarkan dalam pernyataan ini maka segala akibat yang timbul menjadi tanggung jawab saya dan bersedia dituntut sesuai dengan ketentuan peraturan perundang-undangan serta tidak akan melibatkan pihak lain dan saya bersedia sertipikat yang saya terima dibatalkan oleh pejabat yang berwenang.</p>

                  <p class="mb-4">
                  Demikian surat pernyataan ini dibuat dengan disaksikan oleh :
                  </p>
                  
                  <div class="mb-4">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left">1</vs-col>
                     <vs-col vs-w="3">Nama</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                     <vs-col vs-w="3">Umur</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                     <vs-col vs-w="3">Pekerjaan</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                     <vs-col vs-w="3">Alamat</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  </div>
                  
                  <div class="mb-4">
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left">2</vs-col>
                     <vs-col vs-w="3">Nama</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                     <vs-col vs-w="3">Umur</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                     <vs-col vs-w="3">Pekerjaan</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  <vs-row>
                     <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                     <vs-col vs-w="3">Alamat</vs-col>
                     <vs-col vs-w="8">: .............................................................</vs-col>
                  </vs-row>
                  </div>
                  
                  <div class="vx-row">
                  <div class="vx-col w-1/2">
                     <p class="ml-5 mb-base">Saksi-saksi :</p>
                     <vs-row>
                        <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left">1.</vs-col>
                        <vs-col vs-w="11">...............................................</vs-col>
                     </vs-row>
                     <vs-row class="mb-base">
                        <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                        <vs-col vs-w="11">(...............................................)</vs-col>
                     </vs-row>

                     <vs-row>
                        <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left">2.</vs-col>
                        <vs-col vs-w="11">...............................................</vs-col>
                     </vs-row>
                     <vs-row class="mb-base">
                        <vs-col vs-w="1" vs-type="flex" vs-justify="left" vs-align="left"></vs-col>
                        <vs-col vs-w="11">(...............................................)</vs-col>
                     </vs-row>
                     <p class="ml-5">Keterangan :</p>
                     <p class="ml-5">*)coret yang tidak perlu :</p>
                  </div>
                  
                  <div class="vx-col w-1/2">
                     <p class="mt-12">Makassar, </p>
                     <p class="text-center mb-10">Yang membuat pernyataan</p>
                     <p class="text-center mb-10">(Materai 10000)</p>
                     <p class="text-center mb-12">
                        (...............................................)
                     </p>

                     <p class="text-center">Mengetahui</p>
                     <p class="text-center mb-10">Kepala Desa/Lurah</p>
                     <p class="text-center mb-10">(Materai 10000)</p>
                     <p class="text-center">
                        (...............................................)
                     </p>
                  </div>
                  </div>

               </div>
            </section>
            </section>
         </vue-html2pdf>

         <div class="print-loading px-12" v-if="onPro">
            <vs-progress :percent="onPercent" color="primary" class="mx-8">primary</vs-progress>
         </div>
      // -->
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
export default {
   data () {
      return {

         onPercent: 2,
         onPro: false,
         htmlToPdfOptions: {
            image: {
               type: 'jpeg', 
               quality: 2
            },
            /*
            html2canvas: {
               scale: 5,
               useCORS: true
            },
            */
            margin: [5,0,5,0],
            //pagebreak: { mode: 'avoid-all', after: '.avoidThisRow' }
         },
         
         dataNomor: '',
         dataNama: '',
         dataUmur: '',
         dataPekerjaan: '',
         dataKTP: '',
         dataAlamat: '',
         // Kuasa
         dataKuasa: false,
         dataKuasaNama: '',
         dataKuasaUmur: '',
         dataKuasaPekerjaan: '',
         dataKuasaKTP: '',
         dataKuasaAlamat: '',
         // Lokasi
         listKecamatan: [],
         dataKecamatan: 0,
         dataKecamatanNama: '',
         dataChangeKecamatan: false,
         listKelurahan: [],
         dataKelurahan: 0,
         dataKelurahanNama: '',
         dataChangeKelurahan: false,

         dataLayanan: null,
         dataLayananParent: {},
         dataData: [],
         
      }
   },
   components: {
      VueHtml2pdf
   },
   computed: {
      ...mapGetters({
         user : 'auth/user',
      }),
      isFormValid () {
         let kuasa = true
         if (this.dataKuasa == true && 
            this.dataKuasaNama != '' &&
            this.dataKuasaUmur != '' &&
            this.dataKuasaPekerjaan != '' &&
            this.dataKuasaKTP != '' &&
            this.dataKuasaAlamat != '') {
               kuasa = true
         } else if (this.dataKuasa == false) {
            kuasa = true
         } else {
            kuasa = false
         }
         return kuasa && 
         !this.errors.any() &&
         this.dataNomor != '' &&
         this.dataNama != '' &&
         this.dataUmur != '' &&
         this.dataPekerjaan != '' &&
         this.dataKTP != '' &&
         this.dataAlamat != ''
      }
   },
   created () {
      this.$store.commit('navbarUpdateStatus', true)
   },
   mounted () {
      /*
      if (this.$route.params.dataNama) {
         this.dataNama = this.$route.params.dataNama
         this.dataNomor = this.$route.params.dataNomor
      }
      */
      this.mountKecamatan()
      const { 
         id, task_title, task_status, comment_status, task_password, task_type, task_parent, 

         nomor, nama, umur, pekerjaan, ktp, alamat, kuasa, kuasa_nama, kuasa_umur, kuasa_pekerjaan, kuasa_ktp, kuasa_alamat, kecamatan_id, kecamatan_name, kelurahan_id, kelurahan_name,

         layanan 
      } = JSON.parse(JSON.stringify(this.$route.params.submit))

      this.dataLayanan = layanan
      //this.dataLayananParent = parent
      // Task
      this.dataTaskID = id
      this.dataTitle = task_title
      this.dataStatus = task_status
      this.dataCommentStatus = comment_status
      this.dataPassword = task_password
      this.dataType = task_type
      this.dataParent = task_parent

      // Meta
         this.dataNomor = nomor
         this.dataNama = nama
         this.dataUmur = umur
         this.dataPekerjaan = pekerjaan
         this.dataKTP = ktp
         this.dataAlamat = alamat
      // Kuasa
         let kuasaStatus = false
         if (kuasa === 'true') {
            kuasaStatus = true
         }
         this.dataKuasa = kuasaStatus
         this.dataKuasaNama = kuasa_nama
         this.dataKuasaUmur = kuasa_umur
         this.dataKuasaPekerjaan = kuasa_pekerjaan
         this.dataKuasaKTP = kuasa_ktp
         this.dataKuasaAlamat = kuasa_alamat
      // Lokasi
         this.dataKecamatan = kecamatan_id
         this.dataKecamatanNama = kecamatan_name
         this.dataKelurahan = kelurahan_id
         this.dataKelurahanNama = kelurahan_name

      let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
      this.axios.get('/term-parent/'+this.dataKecamatan, configs)
      .then((response) => {
         let {data} = response.data
         this.listKelurahan = data
      })
      .catch((error) => {
         let data = error.data
         this.listKelurahan = data
      }) 
         
      
   },
   methods: {
      mountKecamatan () {
         let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token, }, }
         this.axios.get('/term-list/kecamatan', configs)
         .then((response) => {
            let {data} = response.data
            this.listKecamatan = data
         })
         .catch((error) => {
            let data = error.data
            this.listKecamatan = data
         }) 
      },
      changeKecamatan () {
         this.dataChangeKecamatan = true
      },
      mountKelurahan () {
         if (this.dataChangeKecamatan) {
            const ItemIndex = this.listKecamatan.find((camat) => camat.id == this.dataKecamatan)
            this.dataKecamatanNama = ItemIndex.name
            let configs = { headers: { 'Authorization': 'Bearer ' + this.user.api_token } }
            this.axios.get('/term-parent/'+this.dataKecamatan, configs)
            .then((response) => {
               let {data} = response.data
               this.listKelurahan = data
            })
            .catch((error) => {
               let data = error.data
               this.listKelurahan = data
            }) 
         }
      },
      changeKelurahan () {
         this.dataChangeKelurahan = true
      },
      pilihKelurahan () {
         if (this.dataChangeKelurahan) {
            const ItemIndex = this.listKelurahan.find((lurah) => lurah.id == this.dataKelurahan)
            this.dataKelurahanNama = ItemIndex.name
         }
      },
      backHome () {
         this.$router.push({ 
            name: 'Home'
         });
      },
      updateData () {
         this.$validator.validateAll().then(result => {
         if (result) {
            this.$vs.loading()
            let formData = new FormData()

            formData.set('id', this.dataTaskID)
            formData.set('title', this.dataNama+' - '+this.dataLayanan.task_title)
            formData.set('status', 'publish')
            formData.set('comment_status', 'open')
            formData.set('password', null)
            formData.set('parent', 0)
            formData.set('type', 'berkas')
            formData.set('comment_count', 0)

            let meta = {
               nomor: this.dataNomor,
               nama: this.dataNama,
               umur: this.dataUmur,
               pekerjaan: this.dataPekerjaan,
               ktp: this.dataKTP,
               alamat: this.dataAlamat,
               // Kuasa
               kuasa : this.dataKuasa,
               kuasa_nama : this.dataKuasaNama,
               kuasa_umur : this.dataKuasaUmur,
               kuasa_pekerjaan : this.dataKuasaPekerjaan,
               kuasa_ktp : this.dataKuasaKTP,
               kuasa_alamat : this.dataKuasaAlamat,
               // Lokasi
               kecamatan_id: this.dataKecamatan,
               kecamatan_name: this.dataKecamatanNama,
               kelurahan_id: this.dataKelurahan,
               kelurahan_name: this.dataKelurahanNama, 

               berkas : this.dataLayanan.id,
               berkas_name : this.dataLayanan.task_title
            }
            formData.set('meta', JSON.stringify(meta))

            /*
            const ItemIndex = this.dataLayanan.relation.alur.find((alur) => alur.alur_order === '1')
            let relation =  {
               'alur': [ItemIndex.taxonomy_id]
            }
            formData.set('relation', JSON.stringify(relation))
            */

            formData.set('urutan', 'desc')
            this.$store.dispatch('taskUpdate', formData)
            .then((response) => {
               this.$vs.loading.close()
               this.$vs.notify({
                  title: 'Berhasil',
                  color  : 'success',
                  text  : response.message,
               })
               //this.dataData = response.data.data
               //this.toJadwal()
            })
            .catch(error => {
               this.$vs.loading.close()
               let {data} = error.response
               this.$vs.notify({
                  title: 'Error',
                  text: data.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
               })
            })
         }
         })
      },
      toJadwal () {
         let submit = this.$route.params.submit
         this.$router.push({
            name: "LayananJadwal",
            params: { submit }
         });
      },

      // LAMPIRAN

      onProgress (event) {
        this.onPro = true
        this.onPercent = event
        if (event === 100) {
          this.onPro = false
          this.onPercent = 2
        }
      },
      formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [day, month, year].join('-');
      },
      printPermohonan () {
        this.$refs.Permohonan.generatePdf()
      },
      printGantiBlanko () {
        this.$refs.GantiBlanko.generatePdf()
      },
      printPemecahan () {
        this.$refs.Pemecahan.generatePdf()
      },
      printPengukuran () {
        this.$refs.Pengukuran.generatePdf()
      }
   }
}
</script>

<style lang="scss">
   .dropdown-button-container {
      display: flex;
      align-items: center;

      .btnx {
         border-radius: 5px 0px 0px 5px;
         width: 90%;
      }

      .btn-drop {
         border-radius: 0px 5px 5px 0px;
         border-left: 1px solid rgba(255, 255, 255, .2);
      }
   }

   .print-loading {
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      background: hsla(0,0%,100%,.6);
      z-index: 300000;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
   }
</style>
